import styles from '@/pages/Landing.module.scss';

const LANDING_URL = 'https://timepercentcorp.com/tradingbank/';

const Landing = () => {
  return (
    <main>
      <iframe src={LANDING_URL} className={styles.iframe} />
    </main>
  );
};

export default Landing;
